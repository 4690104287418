export const coverage = {
  type: 'FeatureCollection',
  crs: {
    type: 'name',
    properties: {
      name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
    },
  },
  features: [
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.850569230415289, -54.147059314647336, 0.0],
            [-68.853419297723619, -53.335984988552859, 0.0],
            [-67.498511811250125, -53.326627528559349, 0.0],
            [-67.498480299999983, -53.327551454999934, 0.0],
            [-67.498395371687636, -53.327549798976186, 0.0],
            [-67.469246146759048, -54.137421136148674, 0.0],
            [-68.850569230415289, -54.147059314647336, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [147.11886251390041, -42.539533794072838, 0.0],
            [148.21768165544259, -42.533125625965376, 0.0],
            [148.217693645000054, -42.533758385999931, 0.0],
            [148.217733659269641, -42.533757727310466, 0.0],
            [148.233839903502997, -43.345548882013368, 0.0],
            [147.120436358072141, -43.352140877664937, 0.0],
            [147.11886251390041, -42.539533794072838, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [171.124168193208391, -45.152536581815916, 0.0],
            [171.061955507527841, -45.152916873785962, 0.0],
            [170.999745556528779, -45.152604090392799, 0.0],
            [170.999749093011985, -44.340793481942903, 0.0],
            [170.999749100000031, -44.341467302999945, 0.0],
            [171.061094387787648, -44.341097583362647, 0.0],
            [171.122439323000094, -44.341401682999958, 0.0],
            [171.122437955986356, -44.340727874089076, 0.0],
            [171.122442281800204, -44.340727848017991, 0.0],
            [171.124168193208391, -45.152536581815916, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.995859300385309, -26.276043776953451, 0.0],
            [29.974416451946063, -27.090464682199322, 0.0],
            [29.116342226426276, -27.106169041241717, 0.0],
            [29.101343647928822, -26.291960537209086, 0.0],
            [29.995859300385309, -26.276043776953451, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [49.081068814349059, 27.109360788461576, 0.0],
            [49.066886515159155, 27.923212591599828, 0.0],
            [49.983171313091383, 27.933025185574213, 0.0],
            [49.990638579153668, 27.11848647309025, 0.0],
            [49.081110408553045, 27.109007307450181, 0.0],
            [49.081104521000043, 27.109361417000059, 0.0],
            [49.081068814349059, 27.109360788461576, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.870715549000067, 32.519327665000048, 0.0],
            [19.9346759600338, 32.532399486948549, 0.0],
            [19.923803495000072, 33.434905456000024, 0.0],
            [20.999784831099657, 33.439118619625333, 0.0],
            [20.999784830000067, 33.439575067000078, 0.0],
            [21.052394124324252, 33.439324619072657, 0.0],
            [21.10500277400007, 33.43953061600007, 0.0],
            [21.105002237468963, 33.439074176768273, 0.0],
            [22.075760768769584, 33.434452855282892, 0.0],
            [22.075766264000038, 33.434909188000063, 0.0],
            [22.128370062036481, 33.434202407360502, 0.0],
            [22.180957601000046, 33.433952063000049, 0.0],
            [22.180951571091583, 33.433495926194013, 0.0],
            [23.256368420000058, 33.419046707000064, 0.0],
            [23.23137588000003, 32.429359161000036, 0.0],
            [22.115862523098393, 32.44395473807284, 0.0],
            [21.103840281881133, 32.448646409531136, 0.0],
            [21.102826587000038, 31.546725415000026, 0.0],
            [19.997919522750568, 31.542616509437785, 0.0],
            [18.893502699000067, 31.529415391000043, 0.0],
            [18.870715549000067, 32.519327665000048, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.103941724003366, 32.53687121919527, 0.0],
            [16.16900726700004, 32.531921759000056, 0.0],
            [16.156488877000072, 31.541537113000061, 0.0],
            [14.999789290000024, 31.546766756000068, 0.0],
            [14.999787212258285, 32.448689218516009, 0.0],
            [13.988059955920415, 32.444831329504815, 0.0],
            [12.924756947779738, 32.431798956599316, 0.0],
            [11.810156180000035, 32.408723816000077, 0.0],
            [11.774466652000058, 33.397619778000035, 0.0],
            [12.848973833910442, 33.420463220433781, 0.0],
            [12.848962867000068, 33.420918758000028, 0.0],
            [12.901510480424756, 33.421580121167921, 0.0],
            [12.954088666000075, 33.422697905000064, 0.0],
            [12.954099105562779, 33.422242000508383, 0.0],
            [13.923809021027257, 33.434446749303461, 0.0],
            [13.923803495000072, 33.434905456000024, 0.0],
            [13.976716839543204, 33.43511264600513, 0.0],
            [14.029644942000061, 33.435778798000058, 0.0],
            [14.029649926541696, 33.435319913314736, 0.0],
            [15.10500277400007, 33.43953061600007, 0.0],
            [15.103941724003366, 32.53687121919527, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [67.862806065178646, 37.853410806921374, 0.0],
            [67.875043019539177, 37.040725484006273, 0.0],
            [66.861508626026591, 37.026787009915971, 0.0],
            [66.861494375000063, 37.027307680000035, 0.0],
            [66.861438172170594, 37.027306374346139, 0.0],
            [66.83820644743173, 37.839058609733627, 0.0],
            [67.862806065178646, 37.853410806921374, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [132.815388707084793, 43.244305909672242, 0.0],
            [132.757858445000011, 42.302451615000052, 0.0],
            [131.4869734514416, 42.336761008983586, 0.0],
            [130.214287134000074, 42.357641453000042, 0.0],
            [130.232073155424558, 43.25815464400177, 0.0],
            [130.232032700000104, 43.258155319000032, 0.0],
            [130.232925968973348, 43.301332926082601, 0.0],
            [130.233812138000076, 43.346200009000029, 0.0],
            [130.233854175704721, 43.34619930646663, 0.0],
            [130.252480872000092, 44.246550934000027, 0.0],
            [131.503543184722105, 44.225300730436587, 0.0],
            [131.503571067000053, 44.225975127000027, 0.0],
            [131.503659727156332, 44.225972648854857, 0.0],
            [131.542631357000118, 45.125524883000033, 0.0],
            [132.457946135057796, 45.099534224105732, 0.0],
            [132.41593488, 46.024353396000038, 0.0],
            [133.834108090000086, 46.04762762200005, 0.0],
            [133.854365464000011, 45.059513600000059, 0.0],
            [132.933328422095485, 45.044649312521251, 0.0],
            [132.872873683431379, 44.142835561638528, 0.0],
            [132.815388707084793, 43.244305909672242, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.503571067000053, 44.225975127000027, 0.0],
            [77.50365972715629, 44.225972648854857, 0.0],
            [77.538797540250414, 45.037031832125095, 0.0],
            [77.538706863000087, 45.037034403000064, 0.0],
            [77.540675985910966, 45.080390552236175, 0.0],
            [77.542631357000062, 45.125524883000033, 0.0],
            [77.542725747446624, 45.125522202752805, 0.0],
            [77.583548840000049, 46.024365075000048, 0.0],
            [78.999320560000058, 45.983545206000031, 0.0],
            [78.933076147287423, 45.040886085750934, 0.0],
            [78.8728736834302, 44.142835561619918, 0.0],
            [78.812631659000033, 43.201211355000062, 0.0],
            [77.523281349707617, 43.236610851090838, 0.0],
            [76.232032700000047, 43.258155319000032, 0.0],
            [76.252480872000035, 44.246550934000027, 0.0],
            [77.503543184722105, 44.225300730436587, 0.0],
            [77.503571067000053, 44.225975127000027, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [115.947173490116498, 39.745493094429094, 0.0],
            [115.934562565671811, 40.557243115294106, 0.0],
            [116.999763741565474, 40.562159205808634, 0.0],
            [116.999766547890374, 39.749693865136827, 0.0],
            [115.947212942370996, 39.744916436902777, 0.0],
            [115.947204372000101, 39.745493545000045, 0.0],
            [115.947173490116498, 39.745493094429094, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [60.692080762970051, 42.304227379463029, 0.0],
            [60.723033433000069, 41.441212166000071, 0.0],
            [59.410731332000069, 41.407726189000073, 0.0],
            [59.409021429122326, 41.437982903710783, 0.0],
            [58.197345957000039, 41.457520191000071, 0.0],
            [58.215997023000057, 42.446240862000025, 0.0],
            [58.216037046498421, 42.446240204247232, 0.0],
            [58.232060347633315, 43.25750618054419, 0.0],
            [58.232032700000047, 43.258155319000032, 0.0],
            [58.252480872000042, 44.246550934000027, 0.0],
            [59.626645974000041, 44.22320974000008, 0.0],
            [59.587595803879935, 43.32292106267073, 0.0],
            [60.818123704000072, 43.289081798000041, 0.0],
            [60.757858445000068, 42.302451615000052, 0.0],
            [60.692080762970051, 42.304227379463029, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [84.124061092190104, 51.40812419141897, 0.0],
            [84.017119546094634, 51.411471609747117, 0.0],
            [84.014145618349346, 51.367386498408798, 0.0],
            [83.953869713000074, 50.426293660000056, 0.0],
            [83.815592493932684, 50.428886970976812, 0.0],
            [83.768642413977787, 49.619606220886091, 0.0],
            [83.768514805000052, 49.619610114000068, 0.0],
            [83.768469916796349, 49.618796469663152, 0.0],
            [82.520640126598039, 49.641925750371357, 0.0],
            [82.520571102338266, 49.641927029777811, 0.0],
            [82.520595654000033, 49.642737065000063, 0.0],
            [82.452884409350517, 49.643181643615861, 0.0],
            [82.385237775658965, 49.644435514930606, 0.0],
            [82.385173733000045, 49.644436702000064, 0.0],
            [82.385151359125643, 49.643626365402746, 0.0],
            [81.135217556868099, 49.651833183081536, 0.0],
            [81.137516548939601, 50.462882982254293, 0.0],
            [81.137516548939601, 50.4628829822543, 0.0],
            [81.068615296568808, 50.463340705470912, 0.0],
            [79.660012338122158, 50.455643950947866, 0.0],
            [78.183927395000069, 50.429712991000031, 0.0],
            [78.126453756785551, 51.370921891018277, 0.0],
            [78.071737892288866, 52.221670479668703, 0.0],
            [77.999742287015181, 52.223949096063727, 0.0],
            [76.536584982937029, 52.252080118052788, 0.0],
            [75.07135687410333, 52.262058245734202, 0.0],
            [73.678502536428894, 52.25425650167535, 0.0],
            [73.702026055686247, 51.443996730131765, 0.0],
            [73.702092127000071, 51.443997907000039, 0.0],
            [73.703332694967386, 51.398989976089304, 0.0],
            [73.704578465000054, 51.356079843000032, 0.0],
            [73.704515471553506, 51.356078722314422, 0.0],
            [73.726862340448264, 50.545332066119926, 0.0],
            [73.726924416000031, 50.545333158000062, 0.0],
            [73.726946446132601, 50.544492033235237, 0.0],
            [74.999717688861196, 50.551455341642203, 0.0],
            [74.999717684000075, 50.552292110000053, 0.0],
            [75.068743631731607, 50.551832981324921, 0.0],
            [75.137769756000068, 50.552210622000075, 0.0],
            [75.13776738414505, 50.551373867252032, 0.0],
            [76.549328147000026, 50.541984818000053, 0.0],
            [76.519232758391041, 49.597770925519214, 0.0],
            [76.490669640000078, 48.655385348000038, 0.0],
            [75.066139907189168, 48.664601742970298, 0.0],
            [73.708337936944048, 48.657376206752438, 0.0],
            [72.285371052000073, 48.633031815000038, 0.0],
            [72.236575080989056, 49.517331852597167, 0.0],
            [71.763545148000048, 49.53174440600003, 0.0],
            [71.768642413977801, 49.619606220886091, 0.0],
            [71.768514805000052, 49.619610114000068, 0.0],
            [71.768469916796349, 49.618796469663152, 0.0],
            [70.520640126598039, 49.641925750371357, 0.0],
            [70.517863127000055, 49.55480005700008, 0.0],
            [68.999723421000056, 49.564754999000058, 0.0],
            [68.999718202941551, 50.46296424451446, 0.0],
            [68.722660720387395, 50.46145037332797, 0.0],
            [67.729263580281497, 50.456022347009096, 0.0],
            [67.752783044000068, 49.558033952000073, 0.0],
            [67.283138088624298, 49.549890296086744, 0.0],
            [67.277370382000072, 49.485620291000032, 0.0],
            [65.83145226060536, 49.529675372269601, 0.0],
            [64.382684363000067, 49.556494451000049, 0.0],
            [64.408756599240917, 50.454437909561342, 0.0],
            [63.068615296568808, 50.463340705470912, 0.0],
            [61.729263580281497, 50.456022347009096, 0.0],
            [61.752783044000068, 49.558033952000073, 0.0],
            [61.283138088624298, 49.549890296086744, 0.0],
            [61.277370382000072, 49.485620291000032, 0.0],
            [59.83145226060536, 49.529675372269601, 0.0],
            [58.382684363000067, 49.556494451000049, 0.0],
            [58.385237775658965, 49.644435514930606, 0.0],
            [58.385173733000045, 49.644436702000064, 0.0],
            [58.385151359125643, 49.643626365402746, 0.0],
            [57.135217556868085, 49.651833183081543, 0.0],
            [57.134970503000034, 49.564676297000062, 0.0],
            [55.61676273300003, 49.55648784300007, 0.0],
            [55.590680228493497, 50.454425966734796, 0.0],
            [55.364073857611579, 50.450445084228917, 0.0],
            [55.357621898000048, 50.382122250000066, 0.0],
            [53.884687663361177, 50.427591130183018, 0.0],
            [52.47757458683958, 50.453980739487356, 0.0],
            [51.068615296568808, 50.463340705470912, 0.0],
            [49.729263580281497, 50.456022347009096, 0.0],
            [49.750479625579672, 49.645979962807012, 0.0],
            [49.750537405000046, 49.645980966000081, 0.0],
            [49.751657468620948, 49.6010091507653, 0.0],
            [49.752783044000068, 49.558033952000073, 0.0],
            [49.75272782855366, 49.55803299456278, 0.0],
            [49.772940299917884, 48.746479516112373, 0.0],
            [49.772994464000078, 48.746480444000042, 0.0],
            [49.773013177846572, 48.74569084789335, 0.0],
            [51.132782610000049, 48.752936509000051, 0.0],
            [51.130473948102761, 47.852866477648405, 0.0],
            [52.467277377000073, 47.844325046000051, 0.0],
            [52.440149824000059, 46.856639881000035, 0.0],
            [51.128060414426301, 46.864892740319526, 0.0],
            [51.12606757366985, 46.008584446678398, 0.0],
            [51.123974498000052, 45.065192706000062, 0.0],
            [49.791887518490029, 45.058505290778072, 0.0],
            [48.933340618060136, 45.044649509347565, 0.0],
            [48.933076147287501, 45.040886085752241, 0.0],
            [48.878854797189973, 44.232056865910721, 0.0],
            [49.870237287000101, 44.24783068300011, 0.0],
            [49.887039910470229, 43.347439794309004, 0.0],
            [49.887077835000071, 43.347440388000052, 0.0],
            [49.887877231467947, 43.302570836875098, 0.0],
            [49.888683014, 43.25939191, 0.0],
            [49.88864651791603, 43.259391339593392, 0.0],
            [49.903112208783369, 42.447442473153615, 0.0],
            [49.903148316000056, 42.447443029000056, 0.0],
            [49.903159110291909, 42.446808658643107, 0.0],
            [51.118694174000041, 42.452629745000081, 0.0],
            [51.117034194357871, 41.551173586767327, 0.0],
            [52.316200425000034, 41.544311535000077, 0.0],
            [52.297493099166246, 40.598728171892887, 0.0],
            [52.280465374422725, 39.698102803393546, 0.0],
            [52.264985629859154, 38.841592482016914, 0.0],
            [53.416732203000038, 38.823992346000068, 0.0],
            [53.386988930001458, 37.922831689860743, 0.0],
            [53.996957401124199, 37.907795199495851, 0.0],
            [54.835670159000074, 37.927680433000035, 0.0],
            [54.863974954000071, 36.938667311000074, 0.0],
            [54.477613068595062, 36.929710006361738, 0.0],
            [54.476554344000078, 36.906971813000041, 0.0],
            [53.996233805793189, 36.918549845728982, 0.0],
            [53.632586997000033, 36.910119161000068, 0.0],
            [53.631810571891435, 36.927334196581548, 0.0],
            [53.355776614840629, 36.93398794118503, 0.0],
            [53.328614229000038, 36.034105048000072, 0.0],
            [52.164527550714233, 36.05080405795249, 0.0],
            [51.054073587113074, 36.056448004209393, 0.0],
            [49.943925231595827, 36.051807006782091, 0.0],
            [48.834311636237956, 36.036896406090165, 0.0],
            [48.43749615100004, 36.02788905995164, 0.0],
            [48.43658602000005, 36.007451011000057, 0.0],
            [47.995605115241538, 36.017858540320589, 0.0],
            [47.671306352000045, 36.010497257000054, 0.0],
            [47.670658022226441, 36.025527571704217, 0.0],
            [47.27405887604754, 36.034887653281025, 0.0],
            [46.164527550714247, 36.05080405795249, 0.0],
            [45.054073587113066, 36.056448004209393, 0.0],
            [43.943925231595827, 36.051807006782091, 0.0],
            [42.834311636237956, 36.036896406090165, 0.0],
            [42.43749615100004, 36.02788905995164, 0.0],
            [42.43658602000005, 36.007451011000057, 0.0],
            [41.995605115241538, 36.017858540320589, 0.0],
            [41.671306352000045, 36.010497257000054, 0.0],
            [41.670658022226441, 36.025527571704217, 0.0],
            [41.27405887604754, 36.034887653281025, 0.0],
            [40.164527550714247, 36.05080405795249, 0.0],
            [39.054073587113066, 36.056448004209393, 0.0],
            [37.998529753790557, 36.052035282080404, 0.0],
            [38.00862581634081, 35.239017264713318, 0.0],
            [38.008650049000039, 35.239017584000067, 0.0],
            [38.009181767337239, 35.194247516644033, 0.0],
            [38.009720764000065, 35.150843071000054, 0.0],
            [38.009697270334321, 35.150842762120647, 0.0],
            [38.020408048000036, 34.249007704000064, 0.0],
            [36.934590077413866, 34.235050985077969, 0.0],
            [36.956152796000026, 33.334540339000057, 0.0],
            [36.327352899614077, 33.32120341390128, 0.0],
            [36.294873068959248, 32.449471685118574, 0.0],
            [36.261231085000077, 31.505173744000047, 0.0],
            [35.209688454332415, 31.52724733779953, 0.0],
            [35.188893317000066, 30.626390258000072, 0.0],
            [34.094602836997694, 30.639995665972553, 0.0],
            [33.101861292186705, 30.644368995410279, 0.0],
            [33.100934831000075, 29.742165275000048, 0.0],
            [32.067216235476302, 29.73851918149235, 0.0],
            [32.075396038000065, 28.836297097000056, 0.0],
            [31.050461188442839, 28.824997809083573, 0.0],
            [31.065305499282346, 28.011815750946717, 0.0],
            [31.06534249200007, 28.011816422000038, 0.0],
            [31.065348903245024, 28.011448512453352, 0.0],
            [32.082443501000057, 28.022372192000034, 0.0],
            [32.089906413071112, 27.119521049336317, 0.0],
            [32.089923364000072, 27.119521226000074, 0.0],
            [32.090275724840829, 27.074842303276991, 0.0],
            [32.090636191000044, 27.031233685000075, 0.0],
            [32.090619646069001, 27.031233513084576, 0.0],
            [32.097043069729303, 26.216751266076326, 0.0],
            [33.097706849000076, 26.219896197000025, 0.0],
            [33.096935523971048, 25.272056973066874, 0.0],
            [33.096201244000042, 24.325065923000068, 0.0],
            [32.013993862000063, 24.321894111000063, 0.0],
            [32.006469562248931, 25.268726520800982, 0.0],
            [31.999309164157332, 26.127262231658676, 0.0],
            [30.999813648000043, 26.117197498000053, 0.0],
            [30.9840430603819, 27.019382461883609, 0.0],
            [29.97685264100005, 27.001706217000049, 0.0],
            [29.950888810834218, 27.946947756832287, 0.0],
            [29.92495612988214, 28.848221566937323, 0.0],
            [29.899009183333305, 29.709498067177705, 0.0],
            [29.067363463000049, 29.726055484000028, 0.0],
            [29.086331330378691, 30.628076668838535, 0.0],
            [29.086288982000042, 30.628077535000045, 0.0],
            [29.08724602311586, 30.671575086982962, 0.0],
            [29.08818588400004, 30.716270382000062, 0.0],
            [29.088229397561115, 30.71626948970443, 0.0],
            [29.10807257700003, 31.618142685000066, 0.0],
            [29.992896363309338, 31.599522154673274, 0.0],
            [30.891470220962127, 31.617709668976548, 0.0],
            [30.870715549000067, 32.519327665000048, 0.0],
            [32.039465039000049, 32.53368692600003, 0.0],
            [32.048829955021752, 31.632003958545567, 0.0],
            [32.048850522000066, 31.632004205000044, 0.0],
            [32.048854774207257, 31.63157594283744, 0.0],
            [32.999789090937803, 31.635121129313916, 0.0],
            [32.999789090000036, 31.63554711300003, 0.0],
            [33.051357044262232, 31.635313380248107, 0.0],
            [33.102924144000042, 31.635505628000033, 0.0],
            [33.102923686305019, 31.635079653443842, 0.0],
            [34.054470569131709, 31.630766748759338, 0.0],
            [34.054475257000036, 31.631192629000054, 0.0],
            [34.054498055639584, 31.631192337354904, 0.0],
            [34.063864180677918, 32.44463509372234, 0.0],
            [34.063841231000026, 32.444635394000045, 0.0],
            [34.064364746577951, 32.488108964058114, 0.0],
            [34.064879864000034, 32.532846626000037, 0.0],
            [34.064903480819382, 32.532846316249447, 0.0],
            [34.075766264000038, 33.434909188000063, 0.0],
            [35.150596157323044, 33.420467855096831, 0.0],
            [35.150607190000073, 33.420926216000055, 0.0],
            [35.1506563544743, 33.420925129272206, 0.0],
            [35.171113543698503, 34.233693931709368, 0.0],
            [35.17106347400005, 34.233695062000038, 0.0],
            [35.172212762185282, 34.277366133365675, 0.0],
            [35.173345580000046, 34.322373244000062, 0.0],
            [35.173397180087669, 34.32237207652787, 0.0],
            [35.194770428443036, 35.134520557852163, 0.0],
            [34.205225312459362, 35.148409054329662, 0.0],
            [34.192198189000067, 34.247124376000045, 0.0],
            [33.052892925430157, 34.252663380366194, 0.0],
            [31.913559545000055, 34.248107305000076, 0.0],
            [31.901109322885219, 35.193315639503417, 0.0],
            [31.889296012885655, 36.051072918384378, 0.0],
            [30.83431163623796, 36.036896406090165, 0.0],
            [30.437496151000044, 36.02788905995164, 0.0],
            [30.43658602000005, 36.007451011000057, 0.0],
            [29.995605115241538, 36.017858540320589, 0.0],
            [29.671306352000045, 36.010497257000054, 0.0],
            [29.670658022226441, 36.025527571704217, 0.0],
            [29.274058876047537, 36.034887653281025, 0.0],
            [28.218822308721315, 36.050025190947792, 0.0],
            [28.205203753000035, 35.148895712000069, 0.0],
            [27.10716111994159, 35.154356050029712, 0.0],
            [27.106002496000031, 34.252875760000052, 0.0],
            [25.966976499036274, 34.248320913828358, 0.0],
            [24.881549477909413, 34.234369220031894, 0.0],
            [24.362393543863348, 34.223097372407722, 0.0],
            [24.361730334000072, 34.206816537000066, 0.0],
            [23.994427897448009, 34.215108149083242, 0.0],
            [23.743822336000051, 34.209667033000073, 0.0],
            [23.743384025527867, 34.220775300168221, 0.0],
            [23.17106347400005, 34.233695062000038, 0.0],
            [23.194770428443039, 35.134520557852156, 0.0],
            [22.15153663539871, 35.149162587433096, 0.0],
            [21.053470158244121, 35.15462304403448, 0.0],
            [19.901707091000048, 35.14991205900003, 0.0],
            [19.888691607241952, 36.094958445836426, 0.0],
            [19.875713641693576, 36.996187867369947, 0.0],
            [19.862145474471951, 37.897282211191758, 0.0],
            [19.848644968611257, 38.754636317562031, 0.0],
            [18.698902673000077, 38.738209588000075, 0.0],
            [18.669301510140031, 39.637645539667091, 0.0],
            [18.609383738795387, 39.636171430467719, 0.0],
            [18.607752357000038, 39.605547637000029, 0.0],
            [17.998298066969529, 39.621137376459572, 0.0],
            [17.505487352000046, 39.609013147000041, 0.0],
            [17.50420953915453, 39.633776078893177, 0.0],
            [17.444807198626997, 39.635295580892361, 0.0],
            [17.41525426500845, 38.779213831870337, 0.0],
            [17.384099971000069, 37.835302110000043, 0.0],
            [16.247889310289619, 37.852292614264705, 0.0],
            [16.233725978300665, 36.995099506640933, 0.0],
            [16.21880002000006, 36.050528215000043, 0.0],
            [15.108370440383707, 36.05617203732394, 0.0],
            [15.107159202000048, 35.154842645000031, 0.0],
            [13.955706806617608, 35.15013293068742, 0.0],
            [12.858454812627883, 35.135706976511742, 0.0],
            [12.399153742585295, 35.125506667089795, 0.0],
            [12.361730334000072, 34.206816537000066, 0.0],
            [11.224461827207499, 34.232489629128878, 0.0],
            [10.086006121000025, 34.248111153000025, 0.0],
            [10.097873480281057, 35.149429443161516, 0.0],
            [9.053470158244121, 35.15462304403448, 0.0],
            [7.955706806617608, 35.15013293068742, 0.0],
            [6.858454812627885, 35.135706976511742, 0.0],
            [6.399107227581904, 35.125505634069278, 0.0],
            [6.398328348000064, 35.107218161000048, 0.0],
            [5.995003407447759, 35.116531163351027, 0.0],
            [5.708368323000059, 35.110165477000066, 0.0],
            [5.707832143960704, 35.123162111212388, 0.0],
            [5.248709928241146, 35.133763504393102, 0.0],
            [4.151536635398706, 35.149162587433096, 0.0],
            [3.05347015824412, 35.15462304403448, 0.0],
            [1.955706806617608, 35.15013293068742, 0.0],
            [0.858454812627885, 35.135706976511742, 0.0],
            [0.399107227581905, 35.125505634069278, 0.0],
            [0.398328348000064, 35.107218161000048, 0.0],
            [-0.004996592552241, 35.116531163351027, 0.0],
            [-0.291631676999941, 35.110165477000066, 0.0],
            [-0.292167856039297, 35.123162111212388, 0.0],
            [-0.751290071758854, 35.133763504393102, 0.0],
            [-1.848463364601294, 35.149162587433096, 0.0],
            [-2.892838880058409, 35.154356050029712, 0.0],
            [-2.893941353800245, 34.296564049631584, 0.0],
            [-2.895103253999935, 33.351317077000033, 0.0],
            [-4.022250285860872, 33.346913799347227, 0.0],
            [-5.043882233556967, 33.334085380491707, 0.0],
            [-5.023245810999981, 32.432875435000028, 0.0],
            [-5.706221932648203, 32.418736049411457, 0.0],
            [-5.73876891499998, 31.505173744000047, 0.0],
            [-6.790311545667588, 31.52724733779953, 0.0],
            [-6.811106682999934, 30.626390258000072, 0.0],
            [-7.854364899508466, 30.639361177436573, 0.0],
            [-7.864782104999961, 29.737334822000037, 0.0],
            [-8.949635611445858, 29.741986904829787, 0.0],
            [-10.034512416999974, 29.738160368000024, 0.0],
            [-10.044468254860528, 30.684145590351751, 0.0],
            [-10.054401429399181, 31.586187152096343, 0.0],
            [-10.06479042512553, 32.488104970720308, 0.0],
            [-10.076196504999928, 33.434905456000024, 0.0],
            [-9.000215174009844, 33.439118619605324, 0.0],
            [-9.000217446999955, 34.341663459000074, 0.0],
            [-7.912857451071787, 34.336365057268736, 0.0],
            [-7.912851506999971, 34.336837087000049, 0.0],
            [-7.912825658706248, 34.33683673151657, 0.0],
            [-7.900959187999945, 35.238087535000034, 0.0],
            [-6.80291046767648, 35.2226427123909, 0.0],
            [-6.802897566999945, 35.223132917000044, 0.0],
            [-6.802843945601651, 35.223131676161799, 0.0],
            [-6.780486030574923, 36.035670108707698, 0.0],
            [-7.835472449285761, 36.05080405795249, 0.0],
            [-9.000222066999982, 36.05672396500006, 0.0],
            [-9.000224660113307, 36.957361437079854, 0.0],
            [-10.123629507999965, 36.952564872000039, 0.0],
            [-10.137854525528047, 37.897282211191701, 0.0],
            [-10.152045754800028, 38.798500937091269, 0.0],
            [-10.166882004724778, 39.699299951354611, 0.0],
            [-10.18317260699996, 40.644794804000071, 0.0],
            [-9.000236568515904, 40.650263978900959, 0.0],
            [-9.000239499199596, 41.46314224445112, 0.0],
            [-10.197824794999974, 41.457515206000039, 0.0],
            [-10.215631650923683, 42.401105770584735, 0.0],
            [-10.234305549999931, 43.346194685000057, 0.0],
            [-9.00024679672417, 43.352204296176168, 0.0],
            [-9.000250525999901, 44.2534175810001, 0.0],
            [-7.7475330210831, 44.2458793892554, 0.0],
            [-7.747519128, 44.246550934, 0.0],
            [-7.6862852967161, 44.245510832826398, 0.0],
            [-7.625059941, 44.24514241100011, 0.0],
            [-7.6250751861233, 44.244471134565998, 0.0],
            [-6.4964568152778, 44.225300730436601, 0.0],
            [-6.4964289329999, 44.225975127, 0.0],
            [-6.434883121447, 44.224254856450401, 0.0],
            [-6.373354026, 44.223209740000101, 0.0],
            [-6.3733832552173, 44.222535870121099, 0.0],
            [-5.5029235270192, 44.198205599753699, 0.0],
            [-5.504071407, 44.22596415400011, 0.0],
            [-4.25296777450274, 44.245870375929321, 0.0],
            [-4.25298174599993, 44.24654544100008, 0.0],
            [-4.19137606365134, 44.246850357310052, 0.0],
            [-4.1297627129999, 44.24783068300011, 0.0],
            [-4.12975011073587, 44.24715537394885, 0.0],
            [-3.00025052322008, 44.252745813551229, 0.0],
            [-3.0002505259999, 44.2534175810001, 0.0],
            [-2.938996744276, 44.253048988121101, 0.0],
            [-2.87774350899997, 44.253352160000077, 0.0],
            [-2.87774486564846, 44.252680406693912, 0.0],
            [-1.7475330210831, 44.2458793892554, 0.0],
            [-1.747519128, 44.246550934, 0.0],
            [-1.74747467154154, 44.246550178874713, 0.0],
            [-1.729878858761767, 45.058196536617061, 0.0],
            [-1.729924327999925, 45.058197320000033, 0.0],
            [-1.728938248395467, 45.101584300934491, 0.0],
            [-1.72795903199994, 45.146752855000052, 0.0],
            [-1.727911696569168, 45.146752038219049, 0.0],
            [-1.709484212007687, 45.957551610306425, 0.0],
            [-3.000258129999963, 45.965551148000031, 0.0],
            [-3.000262418823207, 46.864964383392753, 0.0],
            [-4.247883727416235, 46.858509792568569, 0.0],
            [-5.622719637999978, 46.835643714000071, 0.0],
            [-5.669814249241854, 47.777420814040099, 0.0],
            [-5.717000010694337, 48.676001158295591, 0.0],
            [-5.766588738937179, 49.574667858454781, 0.0],
            [-5.818708526759488, 50.472879790543622, 0.0],
            [-5.873546225249053, 51.370921596810497, 0.0],
            [-5.98874587699993, 51.324523354000057, 0.0],
            [-7.493682731839283, 51.353106166304052, 0.0],
            [-8.930049332401657, 51.362769187844627, 0.0],
            [-10.366056010106588, 51.354823219587658, 0.0],
            [-11.870789148999959, 51.32805324800006, 0.0],
            [-11.934201771999939, 52.314023238000061, 0.0],
            [-10.468519221290171, 52.340450011811448, 0.0],
            [-10.49752122788737, 53.194879134351787, 0.0],
            [-10.529699136984414, 54.093297673497311, 0.0],
            [-10.56539836099995, 55.036920542000075, 0.0],
            [-9.000313031305891, 55.046002030822422, 0.0],
            [-9.000320229999943, 55.945734402000028, 0.0],
            [-7.399243787707641, 55.934318478228121, 0.0],
            [-7.39920312299995, 55.935335380000026, 0.0],
            [-7.399096807508539, 55.935333239554964, 0.0],
            [-7.364855903888595, 56.744180528212901, 0.0],
            [-9.000327091999964, 56.755950467000048, 0.0],
            [-9.000335538589988, 57.696805863837611, 0.0],
            [-9.000344531999929, 58.640656186000058, 0.0],
            [-7.277842754920342, 58.628003067416074, 0.0],
            [-7.277789077999955, 58.629130133000046, 0.0],
            [-7.277653127005492, 58.629127312767885, 0.0],
            [-7.232234633999951, 59.526408304000029, 0.0],
            [-6.011430402317076, 59.500846805519075, 0.0],
            [-4.768412635384954, 59.525224842157328, 0.0],
            [-4.768472079999981, 59.526398752000034, 0.0],
            [-4.681519277217027, 59.526928992753881, 0.0],
            [-4.594606560999978, 59.528633523000053, 0.0],
            [-4.594552945577333, 59.527459316007089, 0.0],
            [-3.000353668205643, 59.537180790181075, 0.0],
            [-3.000362873880706, 60.391086727921042, 0.0],
            [-3.000373717999935, 61.334442483000032, 0.0],
            [-1.132067100247639, 61.320345958590963, 0.0],
            [-1.131994967999958, 61.321601534000081, 0.0],
            [-1.040745574339204, 61.319656930298279, 0.0],
            [-0.949435975999961, 61.318967992000069, 0.0],
            [-0.949515135242626, 61.31771273053468, 0.0],
            [0.914902973000039, 61.27798036400003, 0.0],
            [0.801651383161217, 60.336838614453136, 0.0],
            [0.695705058000044, 59.398198448000073, 0.0],
            [-1.064399115316566, 59.435018962969529, 0.0],
            [-1.111835135968144, 58.581292954365061, 0.0],
            [-1.158759381124044, 57.683716742824842, 0.0],
            [-1.202991733346485, 56.78571031917874, 0.0],
            [-1.244716712464554, 55.887813878442891, 0.0],
            [-1.282201536301796, 55.033880439133675, 0.0],
            [0.280574859000069, 55.002751211000032, 0.0],
            [0.210153703096599, 54.114184176093239, 0.0],
            [1.619256721, 54.140186775, 0.0],
            [1.64826500715225, 53.241962151198116, 0.0],
            [1.648341514000037, 53.241963547000069, 0.0],
            [1.648369861187944, 53.24103735477734, 0.0],
            [2.999700258254436, 53.248705033356153, 0.0],
            [2.999700252000025, 53.249626467000041, 0.0],
            [3.072988305751916, 53.24912088220637, 0.0],
            [3.146276872000044, 53.24953673400006, 0.0],
            [3.146273819805059, 53.248615314933048, 0.0],
            [4.49842917852795, 53.239287339184493, 0.0],
            [4.498460428000044, 53.240208349000056, 0.0],
            [4.498545224043165, 53.240206697415225, 0.0],
            [4.530701256, 54.138373318, 0.0],
            [5.976967459479, 54.109881120684001, 0.0],
            [7.468632105456578, 54.137407253976157, 0.0],
            [7.436307534289134, 54.991829315218752, 0.0],
            [7.400379014703944, 55.889934302162409, 0.0],
            [7.362350079985878, 56.787904480915529, 0.0],
            [7.320007931000021, 57.731157476000078, 0.0],
            [8.999664062777951, 57.741209004685835, 0.0],
            [8.99966405300006, 57.742298152000046, 0.0],
            [9.081802093184974, 57.741700541467203, 0.0],
            [9.163941586000021, 57.742192087000035, 0.0],
            [9.163936814393887, 57.741102955082191, 0.0],
            [10.679271826115814, 57.730077854388924, 0.0],
            [10.679320670000038, 57.731166381000037, 0.0],
            [10.679444940534315, 57.731163827790652, 0.0],
            [10.718003787179779, 58.540792038547565, 0.0],
            [10.717875660000061, 58.540794694000056, 0.0],
            [10.72004503140383, 58.583652472731011, 0.0],
            [10.722210922000045, 58.629130133000046, 0.0],
            [10.722346872994509, 58.629127312767885, 0.0],
            [10.767765366000049, 59.526408304000029, 0.0],
            [11.988569597682924, 59.500846805519075, 0.0],
            [13.231587364615102, 59.525224842157328, 0.0],
            [13.231527920000076, 59.526398752000034, 0.0],
            [13.318480722782958, 59.526928992753881, 0.0],
            [13.405393439000022, 59.528633523000053, 0.0],
            [13.405447054422666, 59.527459316007089, 0.0],
            [14.99964636083309, 59.537180790358157, 0.0],
            [14.999646349000045, 59.53834912800005, 0.0],
            [15.086112719169243, 59.537708064759428, 0.0],
            [15.172581183000034, 59.538235352000072, 0.0],
            [15.172575408700077, 59.537067028807137, 0.0],
            [16.767706264301726, 59.525240699640072, 0.0],
            [16.767765366000049, 59.526408304000029, 0.0],
            [16.767912840959085, 59.526405216133156, 0.0],
            [16.811551936605611, 60.336306985989097, 0.0],
            [16.811400483000057, 60.33631018300008, 0.0],
            [16.813842139846749, 60.378811067039578, 0.0],
            [16.816281226000058, 60.424078279000071, 0.0],
            [16.816442525224016, 60.424074871217023, 0.0],
            [16.868005032000042, 61.321601534000081, 0.0],
            [18.914902973000039, 61.27798036400003, 0.0],
            [18.811115981292236, 60.415491183096648, 0.0],
            [19.18290124815082, 60.422848332419441, 0.0],
            [19.131248235000044, 61.321591262000027, 0.0],
            [20.999626261804192, 61.333186032412918, 0.0],
            [20.999615791013262, 62.185996015019462, 0.0],
            [20.999603997171601, 63.083684895732311, 0.0],
            [20.999590707000038, 64.027153880000071, 0.0],
            [23.04558170218781, 64.01134305213229, 0.0],
            [23.045680833000063, 64.012751230000049, 0.0],
            [23.045920067826724, 64.012746007133373, 0.0],
            [23.113722806000055, 64.90914676400007, 0.0],
            [24.888385117954027, 64.870113356421285, 0.0],
            [24.885432403000038, 64.909134773000062, 0.0],
            [27.206383768000023, 64.923995536000064, 0.0],
            [27.199747484222581, 64.025607126197798, 0.0],
            [29.245571461000054, 64.009797589000073, 0.0],
            [29.176155767743648, 63.111470065273231, 0.0],
            [29.99907580484517, 63.093643103496291, 0.0],
            [31.211833157000058, 63.118250128000057, 0.0],
            [31.264979897462617, 62.220920271488389, 0.0],
            [31.265155551000078, 62.220923807000077, 0.0],
            [31.265228102840283, 62.219613015265409, 0.0],
            [32.999615245014944, 62.23046570415331, 0.0],
            [32.999615229000028, 62.231770064000045, 0.0],
            [33.09368984352632, 62.231054363008695, 0.0],
            [33.187767660000077, 62.23164304200003, 0.0],
            [33.187759845024836, 62.230338697112337, 0.0],
            [34.923130873838396, 62.217136338835992, 0.0],
            [34.923210847000064, 62.21843973700004, 0.0],
            [35.017148256648433, 62.216421073252171, 0.0],
            [35.111155869000072, 62.215705882000066, 0.0],
            [35.111068104003188, 62.21440278690843, 0.0],
            [35.996180933770432, 62.195382194485134, 0.0],
            [37.076100825936244, 62.217118559467039, 0.0],
            [37.07602038400006, 62.218429074000028, 0.0],
            [37.170619975634231, 62.219021017927631, 0.0],
            [37.265155551000078, 62.220923807000077, 0.0],
            [37.265228102840283, 62.219613015265409, 0.0],
            [38.999615245014944, 62.23046570415331, 0.0],
            [38.999615229000028, 62.231770064000045, 0.0],
            [39.09368984352632, 62.231054363008695, 0.0],
            [39.187767660000077, 62.23164304200003, 0.0],
            [39.187759845024836, 62.230338697112337, 0.0],
            [41.111155869000072, 62.215705882000066, 0.0],
            [41.05067469483518, 61.317708685091119, 0.0],
            [42.914902973000039, 61.27798036400003, 0.0],
            [42.908828391426418, 61.227499462689046, 0.0],
            [42.871279513494017, 60.915461311868228, 0.0],
            [42.801651383161222, 60.336838614453164, 0.0],
            [42.70050040796513, 59.440683244736341, 0.0],
            [42.605472560986954, 58.544563555563613, 0.0],
            [42.522668713414042, 57.714717812361307, 0.0],
            [43.320057056768114, 57.73006310481324, 0.0],
            [43.320007931000077, 57.731157476000078, 0.0],
            [43.402609977968709, 57.731651789585399, 0.0],
            [43.485183406000033, 57.733240869000042, 0.0],
            [43.485227715041667, 57.732146197064665, 0.0],
            [45.163941586000078, 57.742192087000035, 0.0],
            [45.163551956899525, 57.653258198161467, 0.0],
            [46.675331728146318, 57.642269471647403, 0.0],
            [46.675380337699622, 57.643352775974357, 0.0],
            [46.675263021000035, 57.643355184000029, 0.0],
            [46.677293802162048, 57.685995923069811, 0.0],
            [46.679320671000028, 57.731166381000037, 0.0],
            [46.679444940535291, 57.73116382781118, 0.0],
            [46.718003787179775, 58.540792038547565, 0.0],
            [46.717875660000061, 58.540794694000056, 0.0],
            [46.720045031403828, 58.583652472730968, 0.0],
            [46.722210922000045, 58.629130133000046, 0.0],
            [46.722346872994507, 58.629127312767885, 0.0],
            [46.767765366000049, 59.526408304000029, 0.0],
            [47.988569597682933, 59.500846805519075, 0.0],
            [49.405393439000079, 59.528633523000053, 0.0],
            [49.409455487149522, 59.439672453449589, 0.0],
            [50.999647251622761, 59.449360864063308, 0.0],
            [50.999655478745652, 58.639528446904436, 0.0],
            [50.999655468000071, 58.640656186000058, 0.0],
            [51.083892449576659, 58.640037399690968, 0.0],
            [51.168131162000066, 58.640546363000055, 0.0],
            [51.168125917991929, 58.639418639188783, 0.0],
            [52.722157245079657, 58.628003067416074, 0.0],
            [52.722210922000045, 58.629130133000046, 0.0],
            [52.806357904918627, 58.627384547918467, 0.0],
            [52.890542150000044, 58.626766149000048, 0.0],
            [52.890483245483523, 58.625639411796172, 0.0],
            [53.556250882705584, 58.611828410913837, 0.0],
            [53.467397959000039, 59.490625599000055, 0.0],
            [55.405393439000079, 59.528633523000053, 0.0],
            [55.446367662014403, 58.631275695310187, 0.0],
            [55.446490339000029, 58.631278079000083, 0.0],
            [55.446539032738755, 58.630144646238179, 0.0],
            [57.168131162000066, 58.640546363000055, 0.0],
            [57.163743601526882, 57.697001599159371, 0.0],
            [57.160000623649374, 56.84265684407692, 0.0],
            [58.638861900155717, 56.832003435995311, 0.0],
            [58.638906428000041, 56.833055284000068, 0.0],
            [58.718993021622182, 56.831426188115081, 0.0],
            [58.799105852000082, 56.830849072000035, 0.0],
            [58.799056988202366, 56.82979755250215, 0.0],
            [59.982262348096704, 56.80572916732406, 0.0],
            [60.437638855901476, 56.814405717180861, 0.0],
            [61.360371235052824, 56.831987066580695, 0.0],
            [61.322035616096272, 57.685986881547834, 0.0],
            [61.277100552000036, 58.629120913000065, 0.0],
            [63.168131162000066, 58.640546363000055, 0.0],
            [63.163948676044619, 57.741102868780551, 0.0],
            [64.679271827114377, 57.730077854381655, 0.0],
            [64.679320671000028, 57.731166381000037, 0.0],
            [64.76137727796015, 57.729480480958593, 0.0],
            [64.843465347000063, 57.728883234000079, 0.0],
            [64.843411746528574, 57.727795035763101, 0.0],
            [66.52027595900006, 57.693342899000072, 0.0],
            [66.437638855901469, 56.814405717180861, 0.0],
            [67.360483097244099, 56.831989197955195, 0.0],
            [67.360438313000031, 56.833046680000052, 0.0],
            [67.441052397273381, 56.83352433105199, 0.0],
            [67.521642473000043, 56.835059860000058, 0.0],
            [67.521682866363676, 56.834002079186526, 0.0],
            [68.999672151913444, 56.842759396761423, 0.0],
            [68.999672143000055, 56.843811817000073, 0.0],
            [69.079832435839549, 56.843234358975359, 0.0],
            [69.159994038000036, 56.843709329000035, 0.0],
            [69.159989688226844, 56.842656922853422, 0.0],
            [69.160000623649381, 56.84265684407692, 0.0],
            [69.163551956899525, 57.65325819816146, 0.0],
            [70.675331728146375, 57.642269471647403, 0.0],
            [70.757121890844488, 57.641674960630304, 0.0],
            [70.83908693322978, 57.639992557978296, 0.0],
            [71.650505251419418, 57.623337502473163, 0.0],
            [71.723324493799552, 56.810996398377654, 0.0],
            [71.723324493799552, 56.810996398377647, 0.0],
            [71.982262348096739, 56.80572916732406, 0.0],
            [73.521642473000043, 56.835059860000058, 0.0],
            [73.525036796230793, 56.746172734345187, 0.0],
            [74.99967289601814, 56.754901542835754, 0.0],
            [75.079646004702866, 56.755374927388189, 0.0],
            [75.159626557903536, 56.754799334114409, 0.0],
            [76.635144096111404, 56.744180528212901, 0.0],
            [76.600903192491458, 55.935333239554964, 0.0],
            [76.679025543325693, 55.93376040573817, 0.0],
            [76.757275695000033, 55.933202470000026, 0.0],
            [76.7572310708575, 55.932185897328623, 0.0],
            [77.980397394803418, 55.907559944098566, 0.0],
            [79.398603984303918, 55.934304726346191, 0.0],
            [79.398563086000081, 55.935327061000066, 0.0],
            [79.47730248780401, 55.935788836175398, 0.0],
            [79.556022149000057, 55.937273345000051, 0.0],
            [79.55605903764264, 55.936250711917111, 0.0],
            [80.999679778140447, 55.944716971569619, 0.0],
            [80.999679770000057, 55.945734402000028, 0.0],
            [81.077975233910976, 55.9451761431786, 0.0],
            [81.156271749000041, 55.945635321000054, 0.0],
            [81.156267776774811, 55.944617905184685, 0.0],
            [82.60075621229133, 55.934318478228128, 0.0],
            [82.60079687700005, 55.935335380000026, 0.0],
            [82.679025543325707, 55.93376040573817, 0.0],
            [82.757275695000033, 55.933202470000026, 0.0],
            [82.7572310708575, 55.932185897328623, 0.0],
            [82.757345135358293, 55.932183600873095, 0.0],
            [82.794976148471406, 56.741980339237443, 0.0],
            [83.731050320717628, 56.722957929542858, 0.0],
            [83.731216672016657, 56.722954549038775, 0.0],
            [83.732100176000074, 56.713098547000072, 0.0],
            [83.982075111513211, 56.717856735048244, 0.0],
            [84.427709948000086, 56.708800777000079, 0.0],
            [84.429361859479712, 56.726370686472656, 0.0],
            [84.429495216852544, 56.726373224884981, 0.0],
            [85.364202439493141, 56.744165059599048, 0.0],
            [85.360438313000031, 56.833046680000052, 0.0],
            [85.521682866363676, 56.834002079186526, 0.0],
            [85.521642473000043, 56.835059860000058, 0.0],
            [85.521539163060851, 56.835057891576518, 0.0],
            [85.488782208481581, 57.644331067929556, 0.0],
            [86.99966485141239, 57.653363973897513, 0.0],
            [86.999672151913444, 56.842759396761423, 0.0],
            [87.159994038000036, 56.843709329000035, 0.0],
            [87.159626557903536, 56.754799334114409, 0.0],
            [88.635144096111404, 56.744180528212901, 0.0],
            [88.638906428000041, 56.833055284000068, 0.0],
            [90.435716349000074, 56.796505151000076, 0.0],
            [90.429495216852558, 56.726373224884988, 0.0],
            [91.364202439493141, 56.744165059599055, 0.0],
            [91.398500015331109, 55.934302765681529, 0.0],
            [91.398603984303918, 55.934304726346191, 0.0],
            [91.398563086000081, 55.935327061000066, 0.0],
            [91.477302487804025, 55.935788836175398, 0.0],
            [91.556022149000057, 55.937273345000051, 0.0],
            [91.55605903764264, 55.936250711917111, 0.0],
            [92.999679757808252, 55.944716971450383, 0.0],
            [92.999672143000055, 56.843811817000073, 0.0],
            [94.638861900155717, 56.832003435995311, 0.0],
            [94.638906428000041, 56.833055284000068, 0.0],
            [94.718993021622182, 56.831426188115081, 0.0],
            [94.799105852000082, 56.830849072000035, 0.0],
            [94.799056988202366, 56.82979755250215, 0.0],
            [96.435716349000074, 56.796505151000076, 0.0],
            [96.352169658916935, 55.854668495367143, 0.0],
            [96.27342672900005, 54.915007227000046, 0.0],
            [94.561357442000087, 54.949074010000061, 0.0],
            [94.564870565509324, 55.036926637838128, 0.0],
            [94.564772712000035, 55.036928587000034, 0.0],
            [94.56473551861589, 55.035945076619193, 0.0],
            [93.152758999490544, 55.045906153192789, 0.0],
            [93.15241971100005, 54.959003358000075, 0.0],
            [92.999687652375613, 54.958118060870532, 0.0],
            [92.999693805819092, 54.147152071397329, 0.0],
            [93.149425629000064, 54.148011391000075, 0.0],
            [93.14612669319105, 53.204199650765453, 0.0],
            [93.143007185000045, 52.262459578000062, 0.0],
            [91.606492348378765, 52.253853153642751, 0.0],
            [90.071595116000083, 52.226210870000045, 0.0],
            [90.010840848231808, 53.12230235709454, 0.0],
            [88.495379581000066, 53.151785498000038, 0.0],
            [88.498545224043227, 53.240206697415225, 0.0],
            [88.498460428000044, 53.240208349000056, 0.0],
            [88.498429178527957, 53.239287339184493, 0.0],
            [87.146281940507805, 53.248615258911592, 0.0],
            [87.146126693192329, 53.20419965114953, 0.0],
            [87.143007185000045, 52.262459578000062, 0.0],
            [85.678502536428894, 52.254256501675343, 0.0],
            [85.702026055686247, 51.443996730131772, 0.0],
            [85.631322269498298, 51.442737347838907, 0.0],
            [85.560547850000034, 51.442345252000052, 0.0],
            [85.560574385818128, 51.441477180077499, 0.0],
            [85.560502535549247, 51.44147590027395, 0.0],
            [85.45808017069082, 51.4396515437628, 0.0],
            [84.12370823200007, 51.415883589000032, 0.0],
            [84.124182280670809, 51.408120398051878, 0.0],
            [84.124061092190104, 51.40812419141897, 0.0],
          ],
          [
            [10.280465374422722, 39.698102803393546, 0.0],
            [10.263403219000054, 38.754036048000046, 0.0],
            [9.056053055570551, 38.760560314155263, 0.0],
            [7.84866751800007, 38.755193805000033, 0.0],
            [7.833117995275203, 39.699299951355748, 0.0],
            [7.817599817935011, 40.599963815695695, 0.0],
            [7.801359374098818, 41.50074632686573, 0.0],
            [7.785200619162323, 42.357003532452111, 0.0],
            [6.760228400517529, 42.341251243255108, 0.0],
            [6.757858445000068, 42.302451615000052, 0.0],
            [5.4869734514416, 42.336761008983586, 0.0],
            [4.33304390724585, 42.355693059180332, 0.0],
            [4.314418119000038, 41.456241897000041, 0.0],
            [3.116875583794667, 41.463082866371117, 0.0],
            [3.115445523554306, 40.650206266127306, 0.0],
            [4.182689420896481, 40.644207289484925, 0.0],
            [4.182699623000076, 40.644799649000049, 0.0],
            [4.240526141725127, 40.643882189347849, 0.0],
            [4.298340662000044, 40.643557214000055, 0.0],
            [4.298329467119067, 40.642965097674661, 0.0],
            [5.480449320000048, 40.624209909000058, 0.0],
            [5.444757822000042, 39.635871470000041, 0.0],
            [4.279668029442799, 39.653984878695425, 0.0],
            [4.263403219000054, 38.754036048000046, 0.0],
            [3.056053055570552, 38.760560314155263, 0.0],
            [1.961874779831233, 38.755696981316255, 0.0],
            [1.974663441000075, 37.854977662000067, 0.0],
            [0.838206447431727, 37.839058609733634, 0.0],
            [0.861438172170596, 37.027306374346139, 0.0],
            [0.861494375000063, 37.027307680000035, 0.0],
            [0.861508626026623, 37.026787009914749, 0.0],
            [1.875072281277571, 37.040725886411259, 0.0],
            [1.875064737000059, 37.041249784000058, 0.0],
            [1.93037437429245, 37.041486419842521, 0.0],
            [1.985697462000076, 37.04224724200003, 0.0],
            [1.9857042670314, 37.041723142345553, 0.0],
            [2.999775078578364, 37.046061726226291, 0.0],
            [2.999772365000069, 37.947589572000027, 0.0],
            [4.138065829510349, 37.941546999133614, 0.0],
            [4.138073932000054, 37.942085320000047, 0.0],
            [4.193721210944303, 37.941251555376617, 0.0],
            [4.249354292000021, 37.94095623000004, 0.0],
            [4.249345401128075, 37.940418136692685, 0.0],
            [5.275057195885241, 37.925049870273781, 0.0],
            [5.275073461000034, 37.92559054700007, 0.0],
            [5.331021684386745, 37.924211352891334, 0.0],
            [5.386942157000021, 37.923373495000078, 0.0],
            [5.386925106584831, 37.922833263188274, 0.0],
            [5.9969574011242, 37.907795199495851, 0.0],
            [6.724487932626847, 37.925044388046402, 0.0],
            [6.724471764000043, 37.925581750000049, 0.0],
            [6.780057814536208, 37.926361907145989, 0.0],
            [6.835670159000074, 37.927680433000035, 0.0],
            [6.835685549954741, 37.927142649344447, 0.0],
            [7.861479068980133, 37.941539789209664, 0.0],
            [7.861470921000034, 37.942080916000066, 0.0],
            [7.917448988665487, 37.942325334006945, 0.0],
            [7.973439941000038, 37.94311117400008, 0.0],
            [7.973447290559948, 37.942569840362893, 0.0],
            [8.999772365621352, 37.947051101323119, 0.0],
            [8.999772364000023, 37.947589572000027, 0.0],
            [9.055429592592628, 37.947294118437718, 0.0],
            [9.11108600600005, 37.947537132000036, 0.0],
            [9.11108521487383, 37.946998673402426, 0.0],
            [10.138065829510342, 37.941546999133145, 0.0],
            [10.138073932000054, 37.942085320000047, 0.0],
            [10.193721210968745, 37.941251555376255, 0.0],
            [10.249354292000021, 37.94095623000004, 0.0],
            [10.249345401128075, 37.940418136692685, 0.0],
            [11.275057195885241, 37.925049870273781, 0.0],
            [11.275073461000034, 37.92559054700007, 0.0],
            [11.275135047820992, 37.925589028807437, 0.0],
            [11.303470403000063, 38.826281708000067, 0.0],
            [11.997648159816766, 38.808828379937808, 0.0],
            [12.696086617117755, 38.825717732110832, 0.0],
            [12.696069157000068, 38.826272624000069, 0.0],
            [12.752348395088992, 38.827078231338938, 0.0],
            [12.808652744000028, 38.828439760000037, 0.0],
            [12.808669364257794, 38.827884436036001, 0.0],
            [13.847257448045914, 38.842751271671496, 0.0],
            [13.847248649000051, 38.843310058000043, 0.0],
            [13.903926055999037, 38.843562452632732, 0.0],
            [13.960615720000021, 38.844373935000078, 0.0],
            [13.96062365680498, 38.84381493719205, 0.0],
            [14.999769515615093, 38.848442439599587, 0.0],
            [14.999766839835571, 39.661034689391222, 0.0],
            [13.833868304000077, 39.65575257200004, 0.0],
            [13.818355358525993, 40.55611278258673, 0.0],
            [12.696105071906368, 40.539463651698114, 0.0],
            [11.459177397000076, 40.508462825000038, 0.0],
            [11.408988901887726, 41.437983428186143, 0.0],
            [10.314445998968358, 41.455632047504423, 0.0],
            [10.297493099166241, 40.598728171892702, 0.0],
            [10.280465374422722, 39.698102803393546, 0.0],
          ],
          [
            [0.753767556063969, 40.540319102801625, 0.0],
            [0.780334211726793, 39.729041661985427, 0.0],
            [0.780399208000063, 39.72904326400004, 0.0],
            [0.780417154442391, 39.72846995806686, 0.0],
            [1.832350965337294, 39.743817817906468, 0.0],
            [1.818355358525988, 40.55611278258673, 0.0],
            [0.753767556063969, 40.540319102801625, 0.0],
          ],
          [
            [30.99669740850204, 45.945869342108757, 0.0],
            [30.996171813952408, 45.938738496156617, 0.0],
            [30.930033391000052, 44.997587612000075, 0.0],
            [29.663567816068895, 45.03349435411819, 0.0],
            [29.624700917048848, 44.178367099251162, 0.0],
            [29.58380828, 43.235600960000099, 0.0],
            [28.352524374038101, 43.256144916894101, 0.0],
            [28.333957058751224, 42.399789726226942, 0.0],
            [28.316229192552107, 41.54369979084619, 0.0],
            [29.396681472078829, 41.526248321414066, 0.0],
            [29.396703586000058, 41.526862291000043, 0.0],
            [29.455632236541394, 41.525296148436588, 0.0],
            [29.514538391000031, 41.524344696000071, 0.0],
            [29.514515209143184, 41.523731219852493, 0.0],
            [29.999916531661661, 41.510830743351356, 0.0],
            [30.602839364351173, 41.526242078451588, 0.0],
            [30.602817381000079, 41.526852301000076, 0.0],
            [30.661372171262272, 41.527738237914711, 0.0],
            [30.719948115000079, 41.529235500000027, 0.0],
            [30.719969040732209, 41.528624811489877, 0.0],
            [31.800524743043095, 41.544973673482431, 0.0],
            [31.783516685000052, 42.446235702000081, 0.0],
            [32.99975677638141, 42.452060164670165, 0.0],
            [32.999756774000048, 42.452691199000071, 0.0],
            [33.059225769495377, 42.452344956244524, 0.0],
            [33.118694174000041, 42.452629745000081, 0.0],
            [33.118693012010617, 42.45199872369524, 0.0],
            [34.215985123009233, 42.445610025836949, 0.0],
            [34.215997023000057, 42.446240862000025, 0.0],
            [34.275449517493925, 42.445263809869587, 0.0],
            [34.334891567000057, 42.444917724000049, 0.0],
            [34.334878508981781, 42.444287143991438, 0.0],
            [35.430686568726657, 42.426278453045185, 0.0],
            [35.430710453000074, 42.426911995000069, 0.0],
            [35.490471973220373, 42.425295929816734, 0.0],
            [35.550212933000068, 42.424314137000067, 0.0],
            [35.550187895100279, 42.423681097699266, 0.0],
            [36.763136433000057, 42.390880662000029, 0.0],
            [36.712856129886553, 41.529054221479484, 0.0],
            [36.719948115000079, 41.529235500000027, 0.0],
            [36.719969040732209, 41.528624811489877, 0.0],
            [37.80056197752522, 41.544974236842009, 0.0],
            [37.80055089800004, 41.545588767000027, 0.0],
            [37.859524344905189, 41.54586634041889, 0.0],
            [37.918508193000036, 41.546758769000064, 0.0],
            [37.918518186786372, 41.546144009831856, 0.0],
            [38.999760183204785, 41.55123314830545, 0.0],
            [38.999760181000056, 41.551844670000037, 0.0],
            [39.058396249019573, 41.551509133755019, 0.0],
            [39.117031634000057, 41.551785116000076, 0.0],
            [39.117030558198529, 41.551173607574711, 0.0],
            [40.198958597506227, 41.544982434053928, 0.0],
            [40.198969615000067, 41.54559376800006, 0.0],
            [40.257590652587012, 41.544646920772365, 0.0],
            [40.316200425000034, 41.544311535000077, 0.0],
            [40.316188335371322, 41.543700450771532, 0.0],
            [41.396681472078825, 41.526248321414066, 0.0],
            [41.396703586000058, 41.526862291000043, 0.0],
            [41.396778870659055, 41.526860290165004, 0.0],
            [41.399667907083952, 41.603493384767688, 0.0],
            [41.358109147303431, 42.338875232929738, 0.0],
            [40.214287134000074, 42.357641453000042, 0.0],
            [40.232060347633322, 43.257506180544169, 0.0],
            [38.999753565000098, 43.264790377000097, 0.0],
            [38.999749841132228, 44.164696776545739, 0.0],
            [37.81039690033522, 44.158819295385754, 0.0],
            [36.872946845288233, 44.143926929659507, 0.0],
            [36.869986766000061, 44.099770917000058, 0.0],
            [35.561303436448071, 44.136292657375762, 0.0],
            [34.311759767829031, 44.157483855381642, 0.0],
            [32.999749847000032, 44.165366495000058, 0.0],
            [32.999745947156477, 45.064569047098885, 0.0],
            [31.729416423000032, 45.058191670000042, 0.0],
            [31.708968234895607, 45.957541550968237, 0.0],
            [30.99669740850204, 45.945869342108757, 0.0],
          ],
          [
            [37.062353739872023, 46.836641868403298, 0.0],
            [37.00319261666953, 46.033991125148439, 0.0],
            [37.706918281984215, 46.045540257554165, 0.0],
            [37.687555288115682, 46.857436023304949, 0.0],
            [37.063158910889236, 46.847051150092348, 0.0],
            [37.062353739872023, 46.836641868403298, 0.0],
          ],
          [
            [19.521539163060854, 56.835057891576518, 0.0],
            [19.521642473000043, 56.835059860000058, 0.0],
            [19.521682866363669, 56.834002079186526, 0.0],
            [20.999672128667271, 56.842759396623684, 0.0],
            [20.999664851412383, 57.653363973897513, 0.0],
            [19.488782208481592, 57.644331067929564, 0.0],
            [19.521539163060854, 56.835057891576518, 0.0],
          ],
          [
            [19.446367662014399, 58.631275695310187, 0.0],
            [19.446490339000036, 58.631278079000083, 0.0],
            [19.446539032738762, 58.630144646238179, 0.0],
            [20.999655452349486, 58.639528446744947, 0.0],
            [20.999647250282447, 59.449360864055144, 0.0],
            [19.409455487149469, 59.439672453449596, 0.0],
            [19.446367662014399, 58.631275695310187, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [129.137641415181662, 50.506933913023694, 0.0],
            [129.134970503000091, 49.564676297000062, 0.0],
            [127.684773029247111, 49.556854656550463, 0.0],
            [126.235902710000119, 49.531731207000064, 0.0],
            [126.181291473240606, 50.472879790541938, 0.0],
            [126.123708232000013, 51.415883589000032, 0.0],
            [127.560502535549304, 51.44147590027395, 0.0],
            [127.531528903000094, 52.34134780200003, 0.0],
            [129.143290767000053, 52.350386283000034, 0.0],
            [129.140323657639357, 51.406077783224255, 0.0],
            [129.137641415181662, 50.506933913023694, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [119.768469916796406, 49.618796469663152, 0.0],
            [118.520640127509253, 49.64192575035446, 0.0],
            [118.546481959590977, 50.452688420713947, 0.0],
            [119.815592493932684, 50.428886970976812, 0.0],
            [119.768642413977787, 49.619606220886091, 0.0],
            [119.768514805000109, 49.619610114000068, 0.0],
            [119.768469916796406, 49.618796469663152, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.830001140105125, 29.767945543432987, 0.0],
            [-108.849933288999978, 28.822331106000036, 0.0],
            [-109.924826663058454, 28.83498134231305, 0.0],
            [-111.000205022999978, 28.839465666000024, 0.0],
            [-111.000206914082938, 29.780262035502023, 0.0],
            [-111.000208732437514, 30.644408899976934, 0.0],
            [-111.992659408478318, 30.640812781513514, 0.0],
            [-113.08670609099994, 30.628070840000078, 0.0],
            [-113.10653225764402, 31.528990820397908, 0.0],
            [-113.738374874214827, 31.516234087604868, 0.0],
            [-113.73876891499998, 31.505173744000047, 0.0],
            [-114.007152174368301, 31.510807546075426, 0.0],
            [-114.158820712999955, 31.507745399000044, 0.0],
            [-114.159036408894309, 31.513995843727464, 0.0],
            [-114.842119167728441, 31.528334864251448, 0.0],
            [-115.946524000999943, 31.542427286000077, 0.0],
            [-115.936140877168555, 32.444195822565682, 0.0],
            [-116.896159717208434, 32.448646409526916, 0.0],
            [-116.897173412999962, 31.546725415000026, 0.0],
            [-118.05389732899999, 31.542423814000074, 0.0],
            [-118.064790425125537, 32.48810497072045, 0.0],
            [-118.075128472935546, 33.346249819003184, 0.0],
            [-119.096372460950917, 33.333426271676295, 0.0],
            [-120.222283195999978, 33.309545563000029, 0.0],
            [-120.256671939253877, 34.220776563536539, 0.0],
            [-120.82893652599995, 34.233695062000038, 0.0],
            [-120.805229571556964, 35.134520557852156, 0.0],
            [-121.902146160999962, 35.149916038000072, 0.0],
            [-121.889740002819707, 36.051079875920351, 0.0],
            [-123.000222066999982, 36.05672396500006, 0.0],
            [-123.000224785107633, 37.000774338262701, 0.0],
            [-123.000227368061999, 37.858905184714274, 0.0],
            [-124.13717219599999, 37.85395064800008, 0.0],
            [-124.152045755751885, 38.798500997539527, 0.0],
            [-124.166155294430453, 39.655177002458338, 0.0],
            [-125.330651302999968, 39.638218234000078, 0.0],
            [-125.363138330018813, 40.581837471633179, 0.0],
            [-125.395568205502528, 41.482038773049524, 0.0],
            [-125.429495714366311, 42.381802710260374, 0.0],
            [-125.465011960043469, 43.281410894707925, 0.0],
            [-125.504071407, 44.22596415400011, 0.0],
            [-124.25301157972774, 44.245869678947464, 0.0],
            [-124.271570042368509, 45.101578277519764, 0.0],
            [-124.29202786585455, 46.001351940742843, 0.0],
            [-124.312444711884325, 46.857436023304949, 0.0],
            [-125.622719637999978, 46.835643714000071, 0.0],
            [-125.66981424924181, 47.777420814039104, 0.0],
            [-125.71935849499999, 48.720914869000069, 0.0],
            [-124.360802450667819, 48.744188362199907, 0.0],
            [-124.36082319799999, 48.744977688000063, 0.0],
            [-124.293914985576208, 48.745334214791306, 0.0],
            [-124.227005535999979, 48.746480444000042, 0.0],
            [-124.226986822153478, 48.74569084789335, 0.0],
            [-123.000272103582304, 48.752227514268689, 0.0],
            [-123.000277076999964, 49.652722814000072, 0.0],
            [-121.614848640874399, 49.643626365402739, 0.0],
            [-121.614826267, 49.644436702, 0.0],
            [-121.547115590655324, 49.643181643615897, 0.0],
            [-121.479404345999967, 49.642737065000063, 0.0],
            [-121.479428897660924, 49.641927029777712, 0.0],
            [-120.231530083204007, 49.618796469663003, 0.0],
            [-120.231485195, 49.619610114, 0.0],
            [-120.163456524132997, 49.617534685027998, 0.0],
            [-120.095427237, 49.616273721, 0.0],
            [-120.095474294954997, 49.615460672906003, 0.0],
            [-119.768288899805469, 49.605478851423044, 0.0],
            [-119.769067936999988, 49.619596874000081, 0.0],
            [-118.38570513105789, 49.643615459966171, 0.0],
            [-118.385727631999941, 49.64443007300008, 0.0],
            [-118.317594874056596, 49.644798021804561, 0.0],
            [-118.249462594999954, 49.645980966000081, 0.0],
            [-118.249442299470061, 49.645166077628197, 0.0],
            [-117.000277085242644, 49.651912154861066, 0.0],
            [-117.000281787999938, 50.463798401000076, 0.0],
            [-117.00028231499999, 50.552292110000053, 0.0],
            [-117.000287538558752, 51.404489030607294, 0.0],
            [-117.000293337538977, 52.30520263594309, 0.0],
            [-117.000299430193749, 53.202805533330775, 0.0],
            [-117.000305564607146, 54.059254823837641, 0.0],
            [-118.452947522418356, 54.050927158660521, 0.0],
            [-119.79682279252377, 54.026155458758979, 0.0],
            [-119.797505463999983, 54.017541579000067, 0.0],
            [-120.023190694370044, 54.021982811299338, 0.0],
            [-120.053142424999962, 54.021430710000061, 0.0],
            [-120.053228806935508, 54.022573927676405, 0.0],
            [-121.397878142248047, 54.049035118997601, 0.0],
            [-123.000305553999965, 54.060203804000082, 0.0],
            [-123.000312686360601, 55.002888870653585, 0.0],
            [-123.000319874622392, 55.901317360391907, 0.0],
            [-123.000327460637919, 56.797012600096167, 0.0],
            [-123.00033594699994, 57.742298152000046, 0.0],
            [-121.320728172885623, 57.730077854381655, 0.0],
            [-121.320679328999972, 57.731166381000037, 0.0],
            [-121.320555059464709, 57.73116382781118, 0.0],
            [-121.277789077999955, 58.629130133000046, 0.0],
            [-119.389996794999945, 58.589968871000053, 0.0],
            [-119.47733128647495, 57.714717812363446, 0.0],
            [-118.679942943231936, 57.73006310481324, 0.0],
            [-118.679992068999979, 57.731157476000078, 0.0],
            [-118.597390022031291, 57.731651789585399, 0.0],
            [-118.514816593999967, 57.733240869000042, 0.0],
            [-118.514772284958326, 57.732146197064665, 0.0],
            [-117.000335937222047, 57.741209004685835, 0.0],
            [-117.00033594699994, 57.742298152000046, 0.0],
            [-116.918197906815024, 57.741700541467203, 0.0],
            [-116.836058413999979, 57.742192087000035, 0.0],
            [-116.836063185606108, 57.741102955082191, 0.0],
            [-115.15653465299999, 57.728883234000079, 0.0],
            [-115.202991716478905, 56.785710661624911, 0.0],
            [-115.242654864641764, 55.932183600873103, 0.0],
            [-114.019602605196525, 55.907559944098566, 0.0],
            [-112.601396015696139, 55.934304726346191, 0.0],
            [-112.601436913999976, 55.935327061000066, 0.0],
            [-112.522697512195961, 55.935788836175398, 0.0],
            [-112.443977850999943, 55.937273345000051, 0.0],
            [-112.44394096235736, 55.936250711917111, 0.0],
            [-111.000320221859553, 55.944716971569619, 0.0],
            [-111.000320229999943, 55.945734402000028, 0.0],
            [-110.922024766089024, 55.9451761431786, 0.0],
            [-110.843728250999959, 55.945635321000054, 0.0],
            [-110.843732223225189, 55.944617905184685, 0.0],
            [-109.399243787707647, 55.934318478228121, 0.0],
            [-109.39920312299995, 55.935335380000026, 0.0],
            [-109.320974456674293, 55.93376040573817, 0.0],
            [-109.242724304999967, 55.933202470000026, 0.0],
            [-109.2427689291425, 55.932185897328623, 0.0],
            [-108.019602605196525, 55.907559944098566, 0.0],
            [-106.601396015696139, 55.934304726346191, 0.0],
            [-106.601436913999976, 55.935327061000066, 0.0],
            [-106.522697512195961, 55.935788836175398, 0.0],
            [-106.443977850999943, 55.937273345000051, 0.0],
            [-106.44394096235736, 55.936250711917111, 0.0],
            [-105.000320221859553, 55.944716971569619, 0.0],
            [-105.000320229999943, 55.945734402000028, 0.0],
            [-104.922024766089024, 55.9451761431786, 0.0],
            [-104.843728250999959, 55.945635321000054, 0.0],
            [-104.843732223225189, 55.944617905184685, 0.0],
            [-103.399243787707647, 55.934318478228121, 0.0],
            [-103.39920312299995, 55.935335380000026, 0.0],
            [-103.320974456674278, 55.93376040573817, 0.0],
            [-103.242724304999967, 55.933202470000026, 0.0],
            [-103.2427689291425, 55.932185897328623, 0.0],
            [-101.644031711999958, 55.899998592000031, 0.0],
            [-101.722994066927257, 54.957718863997059, 0.0],
            [-101.794117274534287, 54.060293411579494, 0.0],
            [-101.857580822691077, 53.214489282982889, 0.0],
            [-100.499028159994523, 53.239274913441214, 0.0],
            [-100.499059587999966, 53.240200815000037, 0.0],
            [-100.425354189480672, 53.240619031970951, 0.0],
            [-100.351658485999963, 53.241963547000069, 0.0],
            [-100.351630138812055, 53.24103735477734, 0.0],
            [-99.000299741745565, 53.248705033356153, 0.0],
            [-99.000299747999975, 53.249626467000041, 0.0],
            [-98.92701169424808, 53.24912088220637, 0.0],
            [-98.853723127999956, 53.24953673400006, 0.0],
            [-98.85372618019494, 53.248615314933048, 0.0],
            [-97.501570821472043, 53.239287339184493, 0.0],
            [-97.501539571999956, 53.240208349000056, 0.0],
            [-97.428301751824392, 53.238781885354513, 0.0],
            [-97.35505333499998, 53.238276574000054, 0.0],
            [-97.355087626712702, 53.23735588322117, 0.0],
            [-96.005421753359357, 53.211068245258275, 0.0],
            [-96.00535907699998, 53.211992871000064, 0.0],
            [-95.995218049064135, 53.211667705200313, 0.0],
            [-95.995239074999972, 53.211977826000066, 0.0],
            [-94.351658485999963, 53.241963547000069, 0.0],
            [-94.324146288708334, 52.343053106753111, 0.0],
            [-94.324075449999967, 52.343054384000027, 0.0],
            [-94.322768199508488, 52.298026577021723, 0.0],
            [-94.32145593499996, 52.255150748000062, 0.0],
            [-94.321523390125407, 52.255149533186675, 0.0],
            [-94.297973944313753, 51.443996730131765, 0.0],
            [-94.297907872999986, 51.443997907000039, 0.0],
            [-94.296667305033722, 51.398989976127581, 0.0],
            [-94.295421534999946, 51.356079843000032, 0.0],
            [-94.295484528446551, 51.356078722314422, 0.0],
            [-94.273137659551793, 50.545332066119926, 0.0],
            [-94.273075583999969, 50.545333158000062, 0.0],
            [-94.271890031927754, 50.500068010151871, 0.0],
            [-94.270699107999974, 50.456861170000082, 0.0],
            [-94.270758362301038, 50.456860129056665, 0.0],
            [-94.249520374420385, 49.645979962807012, 0.0],
            [-94.249462594999954, 49.645980966000081, 0.0],
            [-94.249442299470061, 49.645166077628197, 0.0],
            [-92.864786403999972, 49.652643868000041, 0.0],
            [-92.86721364065437, 48.752150996296244, 0.0],
            [-91.639741384312401, 48.744198923363598, 0.0],
            [-91.639720753999939, 48.744984111000065, 0.0],
            [-91.573224948117343, 48.743768002380165, 0.0],
            [-91.506730523999977, 48.743337224000072, 0.0],
            [-91.506753162300129, 48.742552333051258, 0.0],
            [-90.281226129683603, 48.720139280551116, 0.0],
            [-90.28118473699999, 48.720927698000025, 0.0],
            [-90.214371523947776, 48.718916610019697, 0.0],
            [-90.147561290999988, 48.717694751000067, 0.0],
            [-90.14760468428652, 48.716906917885566, 0.0],
            [-89.718469739729784, 48.703989890107522, 0.0],
            [-89.719358494999938, 48.720914869000069, 0.0],
            [-88.360802450667762, 48.744188362199907, 0.0],
            [-88.360823197999935, 48.744977688000063, 0.0],
            [-88.293914985576237, 48.745334214791306, 0.0],
            [-88.227005535999979, 48.746480444000042, 0.0],
            [-88.226986822153478, 48.74569084789335, 0.0],
            [-87.000272091871508, 48.752227514331089, 0.0],
            [-87.000272095999946, 48.753013004000081, 0.0],
            [-86.93374468892209, 48.752582011942728, 0.0],
            [-86.867217389999951, 48.752936509000051, 0.0],
            [-86.867219404728772, 48.752151033638299, 0.0],
            [-85.506730523999977, 48.743337224000072, 0.0],
            [-85.532682348368184, 47.843563305024695, 0.0],
            [-84.197153316999959, 47.819474402000026, 0.0],
            [-84.24434306066351, 46.919793207466952, 0.0],
            [-83.625968473256663, 46.901698178859355, 0.0],
            [-83.627010191999943, 46.923561008000036, 0.0],
            [-82.314543072363151, 46.945420866937638, 0.0],
            [-82.314560752999967, 46.946162221000066, 0.0],
            [-82.249927217192891, 46.946497079406527, 0.0],
            [-82.185289559999944, 46.947573655000042, 0.0],
            [-82.185273612217301, 46.94683204178888, 0.0],
            [-81.000262838482087, 46.952971436986353, 0.0],
            [-81.000262841999984, 46.953709179000043, 0.0],
            [-80.935997839411613, 46.953304386049268, 0.0],
            [-80.871733054999936, 46.953637334000064, 0.0],
            [-80.871734771876746, 46.95289960528703, 0.0],
            [-79.685982301108453, 46.945430777430545, 0.0],
            [-79.685964719999959, 46.946168253000053, 0.0],
            [-79.621726026523433, 46.94502603945579, 0.0],
            [-79.557491901999981, 46.944621441000038, 0.0],
            [-79.557511194224077, 46.943884250181675, 0.0],
            [-78.373549923774178, 46.922832505107124, 0.0],
            [-78.373514644999943, 46.923573058000045, 0.0],
            [-78.30896019575485, 46.921684049897792, 0.0],
            [-78.244415083999968, 46.920536388000073, 0.0],
            [-78.244452067485284, 46.919796397251268, 0.0],
            [-77.625968473256663, 46.901698178859355, 0.0],
            [-77.627010191999943, 46.923561008000036, 0.0],
            [-76.314543072363151, 46.945420866937638, 0.0],
            [-76.314560752999967, 46.946162221000066, 0.0],
            [-76.249927217192905, 46.946497079406527, 0.0],
            [-76.185289559999944, 46.947573655000042, 0.0],
            [-76.185273612217301, 46.94683204178888, 0.0],
            [-75.000262838482087, 46.952971436986353, 0.0],
            [-75.000262841999984, 46.953709179000043, 0.0],
            [-74.935997839411613, 46.953304386049268, 0.0],
            [-74.871733054999936, 46.953637334000064, 0.0],
            [-74.871734771876746, 46.95289960528703, 0.0],
            [-73.685982301108453, 46.945430777430545, 0.0],
            [-73.685964719999959, 46.946168253000053, 0.0],
            [-73.621726026523433, 46.94502603945579, 0.0],
            [-73.557491901999981, 46.944621441000038, 0.0],
            [-73.557511194224077, 46.943884250181675, 0.0],
            [-72.373549923774178, 46.922832505107124, 0.0],
            [-72.373514644999943, 46.923573058000045, 0.0],
            [-72.30896019575485, 46.921684049897792, 0.0],
            [-72.244415083999968, 46.920536388000073, 0.0],
            [-72.244452067485284, 46.919796397251268, 0.0],
            [-71.625968473256663, 46.901698178859355, 0.0],
            [-71.627010191999943, 46.923561008000036, 0.0],
            [-70.314595227722791, 46.945419998261549, 0.0],
            [-70.337134709999987, 47.845914827000058, 0.0],
            [-69.000267354190058, 47.852940629128625, 0.0],
            [-69.000267357999974, 47.853701842000078, 0.0],
            [-68.934898392673247, 47.853284170607864, 0.0],
            [-68.86952963799996, 47.853627711000058, 0.0],
            [-68.86953149723692, 47.852866512441103, 0.0],
            [-67.532722622999984, 47.844325046000051, 0.0],
            [-67.558667424807226, 46.899702585805599, 0.0],
            [-67.581059763574501, 46.044050771688099, 0.0],
            [-66.289455851999946, 46.02142174200003, 0.0],
            [-66.334430147190332, 45.077542020010029, 0.0],
            [-66.377258790999974, 44.135250725000049, 0.0],
            [-67.627074281939997, 44.156446532881347, 0.0],
            [-67.647505968, 43.256794294, 0.0],
            [-68.939992994734396, 43.264434217395831, 0.0],
            [-70.111341863492527, 43.258739196374798, 0.0],
            [-70.096887791216687, 42.447442473153615, 0.0],
            [-70.096851683999944, 42.447443029000056, 0.0],
            [-70.096840889708091, 42.446808658643107, 0.0],
            [-68.881305825999959, 42.452629745000081, 0.0],
            [-68.883126896999954, 41.463692516000037, 0.0],
            [-70.08005002173465, 41.458068588861416, 0.0],
            [-70.065415475999941, 40.557837099000039, 0.0],
            [-71.303894927984445, 40.539463651699734, 0.0],
            [-71.342571614639127, 40.538494307019775, 0.0],
            [-71.344444985999985, 40.504885836000028, 0.0],
            [-72.000949570749938, 40.52199353551223, 0.0],
            [-72.523715127669618, 40.508891585596253, 0.0],
            [-72.555242177999958, 39.635871470000041, 0.0],
            [-73.720331970557197, 39.653984878695425, 0.0],
            [-73.736596780999946, 38.754036048000046, 0.0],
            [-74.887662231935693, 38.760256163415981, 0.0],
            [-74.888979831103441, 37.902726893810303, 0.0],
            [-74.890304012935701, 37.001458457573953, 0.0],
            [-74.891570567844951, 36.100070099162565, 0.0],
            [-74.89278246283105, 35.198252103492344, 0.0],
            [-74.893997503999969, 34.252875760000052, 0.0],
            [-76.033023500963722, 34.248320913828358, 0.0],
            [-77.065409922586127, 34.235050985077969, 0.0],
            [-77.043847203999974, 33.334540339000057, 0.0],
            [-77.672696446479137, 33.321202367264419, 0.0],
            [-77.673257969999952, 33.30678983200005, 0.0],
            [-78.006122249500166, 33.314130363934822, 0.0],
            [-78.222283195999978, 33.309545563000029, 0.0],
            [-78.222636412348379, 33.318905070097664, 0.0],
            [-78.745869067561927, 33.330443724893179, 0.0],
            [-78.76862412, 32.429359161000036, 0.0],
            [-79.832113233685547, 32.443274043532682, 0.0],
            [-79.843511122999985, 31.541537113000061, 0.0],
            [-80.897171703416987, 31.546300898464057, 0.0],
            [-80.89809358995052, 30.688305540444503, 0.0],
            [-80.89897493975802, 29.830032051239563, 0.0],
            [-79.86378599599999, 29.825581037000063, 0.0],
            [-79.874234778450855, 28.878602274662324, 0.0],
            [-79.883744272141584, 27.976212015507464, 0.0],
            [-79.892826096032337, 27.07342800742067, 0.0],
            [-79.901489636385037, 26.170791423732535, 0.0],
            [-79.909751622192331, 25.268044725179767, 0.0],
            [-79.918008069999985, 24.321240289000059, 0.0],
            [-80.951997541536201, 24.324924658498556, 0.0],
            [-81.986006137999937, 24.321894111000063, 0.0],
            [-81.993530437749968, 25.268726520662618, 0.0],
            [-82.000690835842676, 26.127262231658676, 0.0],
            [-83.000186351999957, 26.117197498000053, 0.0],
            [-83.016725056279029, 27.06332396354011, 0.0],
            [-83.033258025077416, 27.965715101136087, 0.0],
            [-83.049721116428856, 28.823512747815137, 0.0],
            [-83.826397117039008, 28.809050623963046, 0.0],
            [-83.826612138999963, 28.802137169000048, 0.0],
            [-84.00853286642203, 28.805659158585719, 0.0],
            [-84.07372505799998, 28.804445245000068, 0.0],
            [-84.073799694966439, 28.806922725930026, 0.0],
            [-84.90030946165875, 28.822923974561753, 0.0],
            [-85.974952778999977, 28.835571268000024, 0.0],
            [-85.965884717511926, 29.737768371529917, 0.0],
            [-86.949635611445913, 29.741986904829787, 0.0],
            [-87.983648733034016, 29.738339772480789, 0.0],
            [-88.966744670933835, 29.727194425935476, 0.0],
            [-88.949508150999975, 28.825378767000075, 0.0],
            [-89.826397117039008, 28.809050623963046, 0.0],
            [-89.826612138999963, 28.802137169000048, 0.0],
            [-90.00853286642203, 28.805659158585719, 0.0],
            [-90.07372505799998, 28.804445245000068, 0.0],
            [-90.073799694966439, 28.806922725930026, 0.0],
            [-90.90030946165875, 28.822923974561753, 0.0],
            [-91.924826663058468, 28.83498134231305, 0.0],
            [-92.950076567638376, 28.839256630531075, 0.0],
            [-93.975038787686628, 28.835741083496664, 0.0],
            [-94.949538811557218, 28.824997809083573, 0.0],
            [-94.933083783999962, 27.923579527000072, 0.0],
            [-95.853143268353037, 27.906937662158249, 0.0],
            [-95.85331393199999, 27.901192456000047, 0.0],
            [-96.008952501587714, 27.904119413724647, 0.0],
            [-96.047858624999947, 27.903415687000063, 0.0],
            [-96.047899948649317, 27.904851864114882, 0.0],
            [-96.868011641595928, 27.920274983596535, 0.0],
            [-96.884508550057944, 27.061872077884683, 0.0],
            [-96.90105127129145, 26.159680398396752, 0.0],
            [-96.916827470383552, 25.257372379603378, 0.0],
            [-96.931866136250306, 24.354670781613212, 0.0],
            [-96.946878407999975, 23.408178823000071, 0.0],
            [-97.973336373847701, 23.418140880100353, 0.0],
            [-98.952329500727757, 23.421507569232631, 0.0],
            [-99.979188668999939, 23.418602984000074, 0.0],
            [-99.98635320474736, 24.365567815622406, 0.0],
            [-99.993180948073899, 25.22474792290145, 0.0],
            [-100.985185212999966, 25.215079504000073, 0.0],
            [-101.00170208899999, 26.205994925000027, 0.0],
            [-100.00144988159154, 26.216098986081875, 0.0],
            [-100.008570582150426, 27.030202844680005, 0.0],
            [-101.01592646099999, 27.019735585000035, 0.0],
            [-101.033258025077473, 27.965715101138745, 0.0],
            [-101.049721116428856, 28.823512747815137, 0.0],
            [-101.826397117039008, 28.809050623963046, 0.0],
            [-101.826612138999963, 28.802137169000048, 0.0],
            [-102.00853286642203, 28.805659158585719, 0.0],
            [-102.07372505799998, 28.804445245000068, 0.0],
            [-102.073799694966439, 28.806922725930026, 0.0],
            [-102.900309461658736, 28.822923974561753, 0.0],
            [-103.924826663058454, 28.83498134231305, 0.0],
            [-105.000205022999978, 28.839465666000024, 0.0],
            [-105.000206836788024, 29.741808531898126, 0.0],
            [-105.932783763613216, 29.738519181495562, 0.0],
            [-105.925409095944687, 28.925102383512161, 0.0],
            [-105.925390779999987, 28.925102586000037, 0.0],
            [-105.925001365264592, 28.880130189625135, 0.0],
            [-105.92460396199999, 28.836297097000056, 0.0],
            [-105.924621814024661, 28.836296900192263, 0.0],
            [-105.916809683999986, 27.934096300000078, 0.0],
            [-106.982769648981872, 27.922680818314774, 0.0],
            [-108.047858624999947, 27.903415687000063, 0.0],
            [-108.075043870116644, 28.848221566895305, 0.0],
            [-108.102191664034009, 29.749358696475642, 0.0],
            [-108.12939288459782, 30.612035688765076, 0.0],
            [-108.811071328892822, 30.62597822090779, 0.0],
            [-108.830001140105125, 29.767945543432987, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [105.143149246619174, 52.305346184266362, 0.0],
            [105.140188799000043, 51.363157842000078, 0.0],
            [103.563305121000042, 51.354432348000046, 0.0],
            [103.534387822546392, 52.252554608233368, 0.0],
            [102.071595116000083, 52.226210870000045, 0.0],
            [102.007830467958797, 53.166703783276702, 0.0],
            [101.94660354258005, 54.022577226849982, 0.0],
            [100.602121857751968, 54.049035118997601, 0.0],
            [98.999694446000035, 54.060203804000082, 0.0],
            [98.999686980000035, 55.046986031000074, 0.0],
            [100.56473551861589, 55.035945076619193, 0.0],
            [100.564772712000035, 55.036928587000034, 0.0],
            [100.564870565509324, 55.036926637838128, 0.0],
            [100.60079687700005, 55.935335380000026, 0.0],
            [102.355968288000042, 55.899998592000031, 0.0],
            [102.277005933073781, 54.957718864009514, 0.0],
            [102.210153703096566, 54.114184176092863, 0.0],
            [103.468720968150606, 54.137408893785711, 0.0],
            [103.468686705000039, 54.138365533000069, 0.0],
            [103.543977986373818, 54.138797634023256, 0.0],
            [103.61925672100007, 54.140186775000075, 0.0],
            [103.619287625332802, 54.139229840401683, 0.0],
            [105.149425629000064, 54.148011391000075, 0.0],
            [105.14612669319105, 53.204199650766576, 0.0],
            [105.143149246619174, 52.305346184266362, 0.0],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Name: '45UXA',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [67.816859062654331, 40.644794950421243, 0.0],
            [67.933194050135185, 40.645332812463593, 0.0],
            [67.933184796000035, 40.645928490000074, 0.0],
            [67.933152480712906, 40.645928009733147, 0.0],
            [67.91994997826535, 41.458068588861416, 0.0],
            [67.802175205000083, 41.457515206000039, 0.0],
            [67.784368349076374, 42.401105770581964, 0.0],
            [67.765694450000069, 43.346194685000057, 0.0],
            [68.999753211572752, 43.352204296216577, 0.0],
            [68.999753209000062, 43.352855392000038, 0.0],
            [69.060093636851676, 43.352498141621552, 0.0],
            [69.120433646000038, 43.352791985000067, 0.0],
            [69.120432390674438, 43.3521409011543, 0.0],
            [70.354447330000028, 43.344834816000059, 0.0],
            [70.352524374038055, 43.256144916894108, 0.0],
            [71.462790485930952, 43.237620140412247, 0.0],
            [71.430790292587574, 42.426909835985754, 0.0],
            [71.430710453000074, 42.426911995000069, 0.0],
            [71.430686568726657, 42.426278453045185, 0.0],
            [70.334921827081118, 42.444286432094628, 0.0],
            [70.333957058750585, 42.399789726196005, 0.0],
            [70.315313290393178, 41.499470292989955, 0.0],
            [70.296637346000068, 40.55547314100005, 0.0],
            [70.181172326531666, 40.556121016491765, 0.0],
            [70.167192187461694, 39.744399362809915, 0.0],
            [70.16715795500005, 39.744399896000061, 0.0],
            [70.167148506686559, 39.743826001949806, 0.0],
            [69.113928008161793, 39.749637953600036, 0.0],
            [69.113779914000077, 39.661551227000075, 0.0],
            [67.833868304000077, 39.65575257200004, 0.0],
            [67.833690823043497, 39.656248706911732, 0.0],
            [66.783190445006198, 39.63987613220764, 0.0],
            [66.783172574299627, 39.640447018751729, 0.0],
            [66.783235343000058, 39.640448563000064, 0.0],
            [66.781810068901578, 39.683972773866195, 0.0],
            [66.780417154441807, 39.728469958066853, 0.0],
            [66.780399208000063, 39.72904326400004, 0.0],
            [66.780334211726796, 39.729041661985427, 0.0],
            [66.72337121684879, 39.727637649944867, 0.0],
            [66.666367169000068, 39.726805953000053, 0.0],
            [66.666386022359475, 39.726233090732912, 0.0],
            [66.666318569060095, 39.72623142815803, 0.0],
            [66.645851199616203, 40.320727303073987, 0.0],
            [66.657452051544624, 40.538494900161623, 0.0],
            [66.696105071906416, 40.539463651698114, 0.0],
            [66.753767556063963, 40.540319102801625, 0.0],
            [67.816856982027687, 40.644917147720307, 0.0],
            [67.816859062654331, 40.644794950421243, 0.0],
          ],
        ],
      },
    },
  ],
}
