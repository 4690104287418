import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={45}
      height={44}
      viewBox="0 0 25.88 21.165"
      {...props}
    >
      <path
        d="M10.813 4.636c.16 0 .317.014.472.035A3.863 3.863 0 0115.062 0a3.86 3.86 0 013.855 3.692 2.306 2.306 0 011.554-.602 2.319 2.319 0 012.318 2.319 2.3 2.3 0 01-.155.826 2.704 2.704 0 11.54 5.354H10.814a3.477 3.477 0 110-6.953"
        fill="#3b3c40"
      />
      <path
        d="M4.125 7.773c.19 0 .377.017.56.042a4.583 4.583 0 014.481-5.542 4.579 4.579 0 014.573 4.38 2.736 2.736 0 011.843-.713 2.75 2.75 0 012.566 3.73 3.208 3.208 0 11.642 6.352H4.125a4.125 4.125 0 010-8.249"
        fill="#efefed"
      />
      <path
        d="M5.818 21.165a.148.148 0 01-.136-.205l1.654-3.928H5.819a.148.148 0 01-.132-.214L7.9 12.39a.148.148 0 01.133-.082h2.36a.148.148 0 01.126.227L8.793 15.26h1.749a.148.148 0 01.112.243l-4.723 5.609a.148.148 0 01-.113.052"
        fill="#f15d46"
      />
    </svg>
  )
}

export default SvgComponent
