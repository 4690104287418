import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={38}
      viewBox="0 0 25.221 16.625"
      {...props}
    >
      <path
        d="M-151.46 5.77a9.556 9.556 0 11-9.555-9.555 9.556 9.556 0 019.556 9.556"
        fill="#3b3c40"
      />
      <path
        d="M43.878 82.389a6.797 6.797 0 11-13.593 0 6.797 6.797 0 0113.593 0"
        fill="#f15d46"
      />
      <path
        d="M23.018 83.496c.202 0 .399.019.593.045a4.845 4.845 0 014.737-5.859 4.84 4.84 0 014.834 4.63 2.894 2.894 0 011.949-.754 2.908 2.908 0 012.713 3.944 3.391 3.391 0 11.678 6.715H23.018a4.36 4.36 0 010-8.72"
        fill="#efefed"
      />
      <g>
        <path
          d="M25.22 6.796a6.797 6.797 0 11-13.593 0 6.797 6.797 0 0113.594 0"
          fill="#3b3c40"
        />
        <path
          d="M4.36 7.904c.202 0 .399.018.593.045A4.846 4.846 0 019.69 2.09a4.84 4.84 0 014.834 4.63 2.894 2.894 0 011.949-.754 2.907 2.907 0 012.713 3.944 3.39 3.39 0 11.678 6.715H4.36a4.36 4.36 0 110-8.72"
          fill="#efefed"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
