import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={55.618}
      height={60.947}
      viewBox="0 0 14.716 16.126"
      {...props}
    >
      <path
        d="M8.418 8.668l-1.06.612-1.06-.612-.002-1.226 1.061-.613 1.062.614zm5.435-.134l-2.933.786-1.13-.652V7.444l1.117-.642 2.946.79a.685.685 0 00.354-1.324l-1.614-.434 1.488-.855a.685.685 0 10-.682-1.188l-1.492.857.432-1.616a.686.686 0 00-1.323-.355L10.23 5.61l-1.125.646L8.04 5.64V4.346l2.155-2.155a.685.685 0 10-.968-.97L8.039 2.408V.685a.685.685 0 00-1.371 0V2.4L5.49 1.221a.684.684 0 10-.969.97l2.147 2.147v1.307l-1.058.61-1.125-.645L3.7 2.677a.686.686 0 00-1.324.355l.433 1.615-1.492-.856a.684.684 0 10-.682 1.188l1.488.855-1.615.433a.685.685 0 10.355 1.324l2.946-.79 1.117.642v1.225l-1.13.653-2.933-.787a.684.684 0 10-.355 1.324l1.61.432-1.485.857a.685.685 0 10.686 1.186l1.491-.86-.434 1.62a.686.686 0 101.324.355l.79-2.945 1.122-.648 1.056.61v1.323L4.52 13.935a.685.685 0 10.97.97l1.177-1.178v1.713a.685.685 0 001.371 0v-1.722l1.187 1.187a.684.684 0 00.968 0 .685.685 0 000-.97L8.04 11.78v-1.31l1.065-.614 1.122.648.79 2.945a.684.684 0 101.323-.355l-.434-1.62 1.492.86a.683.683 0 00.936-.25.685.685 0 00-.25-.936l-1.485-.857 1.609-.432a.684.684 0 10-.354-1.324"
        fill="#3b3c40"
      />
    </svg>
  )
}

export default SvgComponent
