import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={45}
      height={43}
      viewBox="0 0 20.326 19.49"
      {...props}
    >
      <path
        d="M20.326 5.477a5.478 5.478 0 11-10.955 0 5.478 5.478 0 0110.955 0"
        fill="#3b3c40"
      />
      <path
        d="M3.515 6.37c.162 0 .32.015.478.036A3.904 3.904 0 017.81 1.684a3.901 3.901 0 013.896 3.732 2.331 2.331 0 011.57-.608 2.343 2.343 0 012.187 3.179 2.733 2.733 0 11.547 5.412H3.514a3.515 3.515 0 010-7.029"
        fill="#efefed"
      />
      <path
        d="M12.558 15.336l.112-.3a.575.575 0 10-1.077-.402l-.111.3a.575.575 0 101.076.402M11.102 19.229l.392-1.048a.401.401 0 00-.235-.517l-.325-.122a.401.401 0 00-.516.236l-.392 1.049a.4.4 0 00.235.516l.324.121a.4.4 0 00.517-.235M8.722 16.844l.472-1.261a.401.401 0 00-.236-.517l-.324-.121a.4.4 0 00-.517.235l-.471 1.261a.401.401 0 00.235.517l.325.121a.401.401 0 00.516-.235M8.01 18.747l.072-.193a.575.575 0 00-1.076-.403l-.072.193a.575.575 0 001.076.403M6.693 13.733l.192-.512a.574.574 0 10-1.077-.402l-.191.512a.575.575 0 101.076.402M5.37 17.272l.471-1.26a.401.401 0 00-.235-.517l-.325-.121a.402.402 0 00-.516.235l-.472 1.26a.401.401 0 00.236.517l.324.122a.402.402 0 00.517-.236"
        fill="#efefed"
      />
    </svg>
  )
}

export default SvgComponent
